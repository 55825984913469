@font-face {
  font-family: 'icomoon';
  src: url('../../font/icomoon.eot?m1n6');
  src: url('../../font/icomoon.eot?m1n6#iefix') format('embedded-opentype'),
  url('../../font/icomoon.ttf?m1n6') format('truetype'),
  url('../../font/icomoon.woff?m1n6') format('woff'),
  url('../../font/icomoon.svg?m1n6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope-o:before {
  content: "\f003";
}

.icon-home:before {
  content: "\f015";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-facebook-f:before {
  content: "\f09a";
}

.icon-feed:before {
  content: "\f09e";
}

.icon-rss:before {
  content: "\f09e";
}

.icon-google-plus:before {
  content: "\f0d5";
}

.icon-linkedin:before {
  content: "\f0e1";
}

.icon-angle-left:before {
  content: "\f104";
}

.icon-angle-right:before {
  content: "\f105";
}

.icon-tumblr:before {
  content: "\f173";
}

.icon-stumbleupon:before {
  content: "\f1a4";
}

.icon-delicious:before {
  content: "\f1a5";
}

.icon-digg:before {
  content: "\f1a6";
}

.icon-reddit-alien:before {
  content: "\f281";
}
